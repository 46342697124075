<template>
  <f-card
    :header="APP_TITLE"
    header-center
  >
    <f-form @submit="onSubmit">
      <f-col
        class="required"
        md="12"
      >
        <f-input
          v-model="password"
          :label="$t('labels.password')"
          name="password"
          rules="required"
          type="password"
        />
      </f-col>
      <f-col
        class="required"
        md="12"
      >
        <f-input
          v-model="confirmPassword"
          :label="$t('labels.confirmPassword')"
          name="confirmPassword"
          rules="required|confirmed:password"
          type="password"
        />
      </f-col>
      <div class="form-footer mt-5">
        <f-button
          :label="$t('labels.resetPassword')"
          type="submit"
          variant="primary btn-block"
        />
      </div>
    </f-form>
  </f-card>
</template>

<script>
import { APP_TITLE } from '@/config/app.config'

export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
      token: null,
      isProduction: process.env.NODE_ENV === 'production',
      APP_TITLE
    }
  },
  async mounted() {
    this.token = this.$route.query.token

    if (this.isProduction) {
      await this.$recaptchaLoaded()
    }
  },
  methods: {
    async onSubmit() {
      let recaptchaToken

      if (this.isProduction) {
        recaptchaToken = await this.$recaptcha('login')
      }

      await this.$http.put('/auth/resetPassword', {
        token: this.token,
        password: this.password,
        recaptchaToken
      })
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
